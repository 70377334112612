import "./App.css";
function App() {
  return (
    <div className="App">
      <div>
        <h1>Parental Beacon</h1>
        <p>test</p>
        <p>
          Parental Beacon is a web application that allows parents to track
          their children's location and activity.
        </p>
      </div>
    </div>
  );
}

export default App;
