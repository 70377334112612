import React, { useEffect, useState } from "react";
import Logo from "../assets/images/parental-beacon-logo@2x.svg";

export function NavOne() {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    mobileMenu();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".menu-toggler");
    let mainNav = document.querySelector(".main-navigation");

    mainNavToggler.addEventListener("click", function () {
      mainNav.style.display =
        mainNav.style.display !== "block" ? "block" : "none";
    });
  };

  return (
    <header className="site-header site-header__header-one">
      <nav
        className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
          sticky ? "stricked-menu stricky-fixed" : ""
        }`}
        style={{ minHeight: "70px" }}
      >
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <a className="navbar-brand" href="/">
              {/*<img*/}
              {/*  src={logoDark}*/}
              {/*  className="main-logo"*/}
              {/*  width="119"*/}
              {/*  alt="alter text"*/}
              {/*/>*/}
              <img src={Logo} alt="Your SVG" width="250" />
            </a>
            <button className="menu-toggler">
              {/*<span className="fa fa-bars"></span>*/}
            </button>
          </div>
          {/*<div className="main-navigation">*/}
          {/*  <ul className=" one-page-scroll-menu navigation-box">*/}
          {/*    <li className="current scrollToLink">*/}
          {/*      <a href="/">Home</a>*/}
          {/*      <ul className="sub-menu">*/}
          {/*        <li>*/}
          {/*          <a href="/">Home 01</a>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <a href="/index-2">Home 02</a>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <a href="#none">Header Versions</a>*/}
          {/*          <ul className="sub-menu">*/}
          {/*            <li>*/}
          {/*              <a href="/">Header 01</a>*/}
          {/*            </li>*/}
          {/*            <li>*/}
          {/*              <a href="/index-2">Header 02</a>*/}
          {/*            </li>*/}
          {/*          </ul>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </li>*/}
          {/*    <li className="scrollToLink">*/}
          {/*      <a href="#features">Features</a>*/}
          {/*    </li>*/}
          {/*    <li className="scrollToLink">*/}
          {/*      <a href="#video">Video</a>*/}
          {/*    </li>*/}
          {/*    <li className="scrollToLink">*/}
          {/*      <a href="#pricing">Pricing</a>*/}
          {/*    </li>*/}
          {/*    <li className="scrollToLink">*/}
          {/*      <a href="#screenshots">App Screenshots</a>*/}
          {/*    </li>*/}
          {/*    <li className="scrollToLink">*/}
          {/*      <a href="#news">News</a>*/}
          {/*      <ul className="sub-menu">*/}
          {/*        <li>*/}
          {/*          <Link to="/news">News</Link>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <Link to="/news-details">News Details</Link>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          {/*<div className="right-side-box">*/}
          {/*  <a className="thm-btn header__cta-btn" href="#none">*/}
          {/*    <span>Login</span>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </nav>
    </header>
  );
}
