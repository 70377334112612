import React, { useEffect, useState } from "react";
import Logo from "../assets/images/parental-beacon-logo@2x.svg";

export const Footer = () => {
  // constructor() {
  //   super();
  //   this.state = {
  //     scrollBtn: false,
  //   };
  //   this.handleScroll = this.handleScroll.bind(this);
  //   this.scrollTop = this.scrollTop.bind(this);
  // }
  const [scrollBtn, setScrollBtn] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setScrollBtn(true);
      // this.setState({
      //   scrollBtn: true,
      // });
    } else if (window.scrollY < 70) {
      setScrollBtn(false);
      // this.setState({
      //   scrollBtn: false,
      // });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  // componentDidMount() {
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-4 align-items-center">
                <div className="footer-widget footer-widget__about">
                  <img src={Logo} alt="Your SVG" width="220" />
                  <p className="footer-widget__contact">
                    <a href="tel:347-804-3717">347 804 3717</a>
                  </p>

                  <p className="footer-widget__contact">
                    <a href="mailto:info@parentalbeacon.com">
                      info@parentalbeacon.com
                    </a>
                  </p>
                  <p className="footer-widget__contact">
                    3102 Abbey Knoll Dr <br /> Lewis Center, OH 43035
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4 d-flex justify-content-start justify-content-sm-center">
                <div className="footer-widget">
                  <h3 className="footer-widget__title">Links</h3>
                  <ul className="footer-widget__links list-unstyled">
                    <li>
                      <a href="mailto:help@parentalbeacon.com">Help </a>
                    </li>
                    <li>
                      <a href="mailto:support@parentalbeacon.com">Support </a>
                    </li>
                    <li>
                      <a href="mailto:contact@parentalbeacon.com">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-4 d-flex justify-content-start justify-content-sm-end">
                <div className="footer-widget">
                  <h3 className="footer-widget__title">Support</h3>
                  <ul className="footer-widget__links list-unstyled">
                    <li>
                      <a href="mailto:support@parentalbeacon.com">
                        support@parentalbeacon.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 d-flex justify-content-start justify-content-sm-start">
                {/*<div className="footer-widget">*/}
                {/*  <h3 className="footer-widget__title">Explore</h3>*/}
                {/*  <ul className="footer-widget__links list-unstyled">*/}
                {/*    <li>*/}
                {/*      <a href="#none">About</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">Our Team</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">Features</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">Blog</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">How It Works</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/*<div className="footer-widget">*/}
                {/*  <h3 className="footer-widget__title">Services</h3>*/}
                {/*  <ul className="footer-widget__links list-unstyled">*/}
                {/*    <li>*/}
                {/*      <a href="#none">Speed Optimization </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">Marketing Analysis </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">SEO and Backlinks </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#none">Content Marketing</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                <div className="inner-container text-center">
                  <p className="site-footer__copy">
                    {new Date().getFullYear()} by &copy;
                    <span>Parental Beacon LLC</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 d-flex justify-content-start justify-content-sm-end mb-3">
                <div className="bottom-links">
                  <a href="/terms.html" target="_blank">
                    Terms
                  </a>
                  <a href="/privacy.html" target="_blank">
                    Privacy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div
        onClick={scrollTop}
        onKeyDown={scrollTop}
        role="button"
        tabIndex="0"
        className="scroll-to-target scroll-to-top"
        style={{ display: scrollBtn ? "block" : "none" }}
      >
        <i className="fa fa-angle-up"></i>
      </div>
    </div>
  );
};
